const state = {
  charts: [],
  rank: []
}

const mutations = {
  setCharts (state, payload) {
    state.charts = payload
  },
  setRank (state, payload) {
    state.rank = payload.broad_cast_flow
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}