/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-31 17:00:32
 * @Module: form组件下拉菜单的api
 */
import request from '@/utils/request'
export default {
	//选择经纪人
	brokerInviterOptions () {
		return request.post(`api/broker/broker-inviter-options`)
	},
	// 选择公会
	mcnListOptions () {
		return request.post(`api/anchor/mcn-list`)
	},
	// 选择分成比
	ratioListOptions () {
		return request.post(`api/anchor/ratio-list`)
	},
	// 选择审批状态
	inviteStateListOptions () {
		return request.post(`api/anchor/invite-state-list`)
	},
	// 选择合作时间
	anchorMonthOptions () {
		return request.post(`api/anchor/month-options`)
	},
	// 选择王牌运营
	superBrokerOptions (params) {
		return request.post(`mmapi/zn/super-broker/list`, params)
	}
}
