<template>
  <div class="header">
    <div class="title-wrap">
      <h1>
        <img class="logo"
             src="../../static/img/logo-l.png"
             alt />
        探探联营后台·商家版
      </h1>
      <span :class="`icon iconfont ${!isCollapse ? 'icon-shouqi' : 'icon-zhankai'}`"
            @click="switchCollapse"></span>
    </div>
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        {{userInfo && userInfo.name}}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="copy"
                          icon="icon iconfont icon-fuzhiiocn">主播提现地址</el-dropdown-item>
        <el-dropdown-item command="toKuaiShou"
                          icon="icon iconfont icon-qiehuan">切换到快手</el-dropdown-item>
        <el-dropdown-item command="toMoMo"
                          icon="icon iconfont icon-qiehuan">切换到陌陌</el-dropdown-item>
        <el-dropdown-item command="exit"
                          icon="icon iconfont icon-tuichuiocn">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      isCollapse: false,
      headerDialogVisible: false
    };
  },
  methods: {
    handleCommand (command) {
      if (command === "exit") {
        this.$store.commit("user/clearUserInfo");
        localStorage.clear();
        this.$router.push({ path: "/login" });
      } else if (command === "toKuaiShou") {
        window.location.href = "https://live.mcn-union.com/";
      } else if (command === "toMoMo") {
        window.location.href = "http://momo.mcn-open.com/";
      } else if (command === "copy") {
        this.$emit('showModal')
      }
    },
    switchCollapse () {
      this.isCollapse = !this.isCollapse;
      this.$emit("changeCollapse");
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 60px;
  width: 100%;
  padding: 0 20px 0 30px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  line-height: 60px;
}
h1 {
  color: #333333;
  font-size: 21px;
  line-height: 60px;
  font-weight: 500;
}
.header /deep/ span.el-dropdown-link.el-dropdown-selfdefine.cc_pointer {
  cursor: pointer !important;
}
.title-wrap {
  display: flex;
  justify-content: space-between;
}
.iconfont {
  margin-left: 20px;
  cursor: pointer;
}
.icon-shouqi,
.icon-zhankai {
  color: #999;
}
.logo {
  display: inline-block;
  height: auto;
  width: 30px;
  vertical-align: -8px;
  margin-right: 2px;
}
</style>