import request from '@/utils/request'

export default {
  brokerListApi (params) {
    return request.post(`api/broker/broker-list`, params)
  },
  brokerTreeListApi (params) {
    return request.post(`api/broker/broker-filter-tree`, params)
  },
  belongerListApi (params) {
    return request.post(`api/profit/plate-form`, params)
  },
  brokerInviterAddApi (params) {
    return request.post(`api/broker/broker-inviter-options`, params)
  },
  brokerRolesApi (params) {
    return request.post(`api/broker/broker-roles`, params)
  },
  brokerDelApi (params) {
    return request.post(`api/broker/delete/${params.id}`, params)
  },
  brokerUpApi (params) {
    return request.post(`api/broker/update/${params.id}`, params)
  },
  brokerCreateApi (params) {
    return request.post(`api/broker/create`, params)
  },
  areaAuthApi (params) {
    return request.post(`api/broker/settle-permission/${params.id}`, params)
  },
  flowTicketApi (params) {
    return request.post(`api/coupon/broker-transfer`, params)
  },
  increaseRatioApi (params) {
    return request.post(`api/broker/update-increase-ratio`, params)
  },
  walletTransferApi (params) {
    return request.post(`api/wallet/transfer`, params)
  },
  auditListApi (params) {
    return request.post(`api/accept/list`, params)
  },
  checkUpdateApi (params) {
    return request.post(`api/accept/update/${params.id}`, params)
  },
  contractAuthApi (params) {
    return request.post(`api/broker/update-contract-status`, params)
  },
  packageAuthApi (params) {
    return request.post(`api/broker/separate-permission`, params)
  },
  // 审核主播分成比
  acceptRatioListApi (params) {
    return request.post(`api/accept/ratio-list`, params)
  },
  // 代理商修改经纪人
  brokerUpdateBrokerInfoApi (params) {
    return request.post(`api/broker/update-broker-info/${params.id}`, params)
  },
  // 修改经纪人余额验证码接口
  phoneTransferVerify(){
    return request.post(`api/phone/transfer-verify`)
  }
}
