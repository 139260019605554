<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  created() {
    let data = this.$getStorage('userInfo');
    if (data && data.token) {
      this.$store.commit('user/setUserInfo', data)
		}
  }
};
</script>

<style lang="less">
@import "~@/assets/style/reset.less";
@import "~@/assets/iconfont/iconfont.css";
@import "~@/assets/style/main.less";
</style>
