import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import home from './modules/home'
import layouts from './modules/layouts'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    layouts,  // layout配置
    user, //用户信息
    home, //首页
  }
})

export default store
