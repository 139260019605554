import homeapi from './homeapi'
import guildManageapi from './guildManageapi'
import agent from './agent'
import loginapi from './loginapi'
import anchorListapi from './anchorListapi'
import liveDataLsitapi from './liveDataLsitapi'
import formComponentapi from './formComponentapi'
import staffList from './staffList'
import personalapi from './personalapi'
import finance from './finance'
import enrollmentListapi from './enrollmentListapi'
import anchorMonitorapi from './anchorMonitorapi'

export default {
	...homeapi,
	...guildManageapi,
	...agent,
	...loginapi,
	...anchorListapi,
	...liveDataLsitapi,
	...formComponentapi,
	...staffList,
	...personalapi,
	...finance,
	...enrollmentListapi,
	...anchorMonitorapi
}
