export const routes = [
  {
    path: '/',
    component: () => import('@/views/home'),
    redirect: '/home',
    meta: {
      title: '首页',
      icons: 'el-icon-s-home',
    },
  },
  {
    path: '/home',
    component: () => import('@/views/home'),
    meta: {
      title: '首页',
      icons: 'el-icon-s-home',
    },
  },
  {
    path: '/home/contactus',
    component: () => import('@/views/home/contactus'),
    meta: {
      title: '联系我们',
      icons: 'el-icon-s-home',
    },
  },
  {
    path: '/home/notice/:id',
    component: () => import('@/views/home/notice'),
    meta: {
      title: '平台公告',
      icons: 'el-icon-s-home',
    },
  },
  {
    path: '/backstage',
    component: () => import('@/views/backstage'),
    redirect: '/backstage/guildpage',
    meta: {
      title: '后台管理',
      auth: 'broker,personal',
      icons: 'el-icon-tickets',
    },
    children: [
      {
        path: '/backstage/guildpage',
        component: (resolve) => require(['@/views/backstage/guildpage'], resolve),
        meta: {
          auth: 'broker,personal',
          title: '公会管理',
          icons: 'el-icon-tickets',
        },
      },
      {
        path: '/backstage/importdata',
        component: (resolve) => require(['@/views/backstage/importdata'], resolve),
        meta: {
          auth: 'broker,personal',
          title: '数据导入',
          icons: 'el-icon-tickets',
        },
      },
      {
        path: '/backstage/noticepage',
        component: (resolve) => require(['@/views/backstage/noticepage'], resolve),
        name: 'noticepage',
        meta: {
          auth: 'broker,personal',
          title: '公告管理',
          icons: 'el-icon-tickets',
        },
        children: [
          {
            path: '/backstage/noticeadd',
            component: () => import('@/views/backstage/noticeadd'),
            meta: {
              auth: 'broker,personal',
              title: '新增',
              icons: 'el-icon-s-home',
            },
          },
          {
            path: '/backstage/noticeedit',
            component: () => import('@/views/backstage/noticeedit'),
            meta: {
              auth: 'broker,personal',
              title: '编辑',
              icons: 'el-icon-s-home',
            },
          },
        ]
      },

    ],

  },
  {
    path: '/personalpage',
    component: () => import('@/views/personal/personalpage'),
    meta: {
      title: '个人中心',
      icons: 'el-icon-tickets',
    },
  },
  {
    path: '/agentmanage',
    component: () => import('@/views/agentmanage'),
    redirect: '/agentmanage/agentlist',
    meta: {
      title: '经纪人管理',
      icons: 'el-icon-tickets',
    },
    children: [
      {
        path: '/agentmanage/agentlist',
        component: (resolve) => require(['@/views/agentmanage/agentlist'], resolve),
        meta: {
          title: '经纪人列表',
          icons: 'el-icon-tickets',
        },
      },
      {
        path: '/agentmanage/agentlist/agentadd',
        component: (resolve) => require(['@/views/agentmanage/agentAdd'], resolve),
        meta: {
          auth: 'personal',
          title: '新增',
          icons: 'el-icon-tickets',
        }
      },
      {
        path: '/agentmanage/agentlist/agentedit',
        component: (resolve) => require(['@/views/agentmanage/agentedit'], resolve),
        name: 'agentedit',
        meta: {
          auth: 'personal',
          title: '编辑',
          icons: 'el-icon-tickets',
        }
      },
      {
        path: '/agentmanage/check',
        component: (resolve) => require(['@/views/agentmanage/checkList'], resolve),
        meta: {
          title: '审核管理',
          icons: 'el-icon-tickets',
        }
      }
    ]
  },
  // {
  //   path: '/stafflist',
  //   component: () => import('@/views/staffList'),
  //   meta: {
  //     title: '员工列表',
  //     icons: 'el-icon-s-home',
  //   },
  // },
  {
    path: '/anchor',
    redirect: '/anchor/anchorList',
    component: () => import('@/views/anchor'),
    meta: {
      title: '主播管理',
      icons: 'el-icon-tickets',
    },
    children: [
      {
        path: '/anchor/anchorList',
        component: (resolve) => require(['@/views/anchor/anchorList'], resolve),
        name: 'anchorList',
        meta: {
          title: '主播列表',
          icons: 'el-icon-tickets',
        },
        children: [
          {
            path: 'anchorDetail',
            component: (resolve) => require(['@/views/anchor/anchorDetail'], resolve),
            meta: {
              title: '主播详情',
            },
            name: 'anchorDetail',
            children: [
              {
                path: 'anchorEdit',
                name: "anchorEdit",
                component: (resolve) => require(['@/views/anchor/anchorEdit'], resolve),
                meta: {
                  title: '编辑主播',
                },
              },
            ]
          },
          {
            path: '/anchor/anchorAdd',
            component: (resolve) => require(['@/views/anchor/anchorAdd'], resolve),
            meta: {
              title: '新增主播',
            },
          },
{
						path: '/anchor/anchorMonitor',
						component: (resolve) => require(['@/views/anchor/anchorMonitor'], resolve),
						meta: {
							title: '直播监控',
						},
					},
        ]
      },

      {
        path: '/anchor/liveDataLsit',
        component: (resolve) => require(['@/views/anchor/liveDataLsit'], resolve),
        meta: {
          title: '直播数据',
          icons: 'el-icon-tickets',
        },
      },
      {
        path: '/anchor/enrollmentList',
        component: (resolve) => require(['@/views/anchor/enrollmentList'], resolve),
        meta: {
          title: '主播入会审批',
          icons: 'el-icon-tickets'
        },
      },
      {
        path: '/anchor/enrollment',
        component: (resolve) => require(['@/views/anchor/enrollment'], resolve),
        meta: {
          auth: 'staff,personal',
          title: '通过审批'
        }
      },
    ],

  },
  {
    path: '/finance',
    component: () => import('@/views/finance'),
    redirect: '/finance/liveincome',
    meta: {
      title: '财务',
      icons: 'el-icon-tickets',
    },
    children: [
      {
        path: '/finance/liveincome',
        component: (resolve) => require(['@/views/finance/liveincome'], resolve),
        meta: {
          title: '直播收入',
          icons: 'el-icon-tickets',
        },
      },
      {
        path: '/finance/operation',
        component: (resolve) => require(['@/views/finance/operation'], resolve),
        meta: {
          title: '经营分析',
          icons: 'el-icon-tickets',
        },
      },
      {
        path: '/finance/withdraw',
        component: (resolve) => require(['@/views/finance/withdraw'], resolve),
        meta: {
          title: '提现管理',
          icons: 'el-icon-tickets',
        },
      },
      {
        path: '/finance/acwithdraw',
        component: (resolve) => require(['@/views/finance/acwithdraw'], resolve),
        meta: {
          title: '主播提现管理',
          icons: 'el-icon-tickets',
        },
      },
      {
        path: '/finance/withdraw/detail',
        component: (resolve) => require(['@/views/finance/withdrawDetail'], resolve),
        name: 'withdrawDetail',
        meta: {
          title: '提现',
          icons: 'el-icon-tickets',
        },
      }
    ]
  },
  {
    path: '/401',
    name: 'error_401',
    component: (resolve) => require(['@/views/401'], resolve),
  },
  {
    path: '*',
    name: 'error_404',
    component: (resolve) => require(['@/views/404'], resolve),
  },
]
