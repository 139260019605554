/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-26 16:40:15
 * @Module: 直播数据api
 */
import request from '@/utils/request'
export default {
	//  直播数据列表
	liveDataLsitApi(params) {
		return request.post(`api/data/list`, params)
	}
}