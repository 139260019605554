import request from '@/utils/request'

export default {
  businessChartsApi (params) {
    return request.post(`api/business/charts`, params)
  },
  incomeListApi (params) {
    return request.post(`api/income/list`, params)
  },
  businessListApi (params) {
    return request.post(`api/business/list`, params)
  },
  withdrawListApi (params) {
    return request.post(`api/withdraw/list`, params)
  },
  withdrawSwitchApi (params) {
    return request.post(`api/withdraw/switch`, params)
  },
  withdrawSwitchOpenedApi (params) {
    return request.post(`api/withdraw/switch-opened`, params)
  },
  withdrawCreateApi (params) {
    return request.post(`api/withdraw/create`, params)
  },
  withdrawStoreApi (params) {
    return request.post(`api/withdraw/store`, params)
  },
  withdrawApplyApi (params) {
    return request.post(`api/withdraw/apply/${params.id}`, params)
  },
  withdrawAnchorListApi (params) {
    return request.post(`api/withdraw/anchor-list`, params)
  },
  withdrawAnchorApplyApi (params) {
    return request.post(`/api/withdraw/anchor-apply/${params.id}`, params)
  },
    // �����ѡ���б�
  reviewerOptions(params){
    return request.post(`api/reviewer/options`, params)
  },
  // ���Ӵ����
  reviewerStore(params){
    return request.post(`api/reviewer/store`, params)
  },
  //ɾ�������
  reviewerDestroy(params){
    return request.post(`api/reviewer/destroy/${params.id}`)
  }
}
