/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-25 10:56:33
 * @Module: 登陆页面api
 */
import request from '@/utils/request'
export default {
	//登录
  authLoginApi (params) {
    return request.post(`api/auth/${params.type=='account'?'login':'phone-login'}`, params)
	},
	// 获取验证码
  verifyCodeApi (params) {
    return request.post(`api/phone/login/verify`, params)
  },
	// 找回密码
  forgetPasswordApi (params) {
    return request.post(`api/auth/forget-password`, params)
	},
	// 获取验证码(注册  需要验证手机号是否重复)
  registerVerifyeApi (params) {
    return request.post(`api/phone/register/verify`, params)
	},
	// 注册
	authRegisterApi (params) {
    return request.post(`api/auth/register`, params)
	}
}
