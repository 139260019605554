<template>
  <div class="breadcrumb-wrap">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(item,idx) in pathList"
                          :key="idx"
                          :class="item.path !== currentPath && item.path ? 'aclink' : 'bclink'"
                          @click.native="getRouter(item)">{{item.name}}</el-breadcrumb-item>
    </el-breadcrumb>
    <span v-show="currentPath === '/finance/withdraw/detail'"
          class="report-phone">如提现未能如期到账，请咨询<a target="_blank"
         href="http://www.wanghongtoutiao.cn/#/"
         class="blue-text">在线客服</a>或<span class="blue-text">致电400-119-3009</span>&nbsp;&nbsp;客服工作时间：<span class="blue-text">周一至周六，14:00-22:00</span></span>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      currentPath: ''
    }
  },
  methods: {
    getRouter (item) {
      if (item.path !== this.currentPath) {
        this.$router.push({ path: item.path })
      }
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        this.currentPath = val.path
      },
      immediate: true
    },
  },
  computed: {
    ...mapState({
      pathList: (state) => state.layouts.pathList,
    }),
  },
}
</script>

<style lang="less" scoped>
.el-breadcrumb {
  margin-top: 9px;
  margin-bottom: 25px;
}
.aclink {
  cursor: pointer;
  & /deep/ .el-breadcrumb__inner:hover {
    color: #ff3b00;
  }
}
.el-breadcrumb /deep/ .el-breadcrumb__inner {
  font-size: 20px;
  font-weight: normal;
}
.bclink {
  & /deep/ .el-breadcrumb__inner {
    color: #333333 !important;
  }
}
.report-phone {
  color: #333;
  font-size: 14px;
  margin-top: 9px;
  line-height: 18px;
  margin-left: 10px;
}
.breadcrumb-wrap {
  display: flex;
  justify-content: flex-start;
}
.blue-text {
  color: #509ded;
}
</style>