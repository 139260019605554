import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import echarts from 'echarts'
import moment from 'moment'
import VueClipboard from 'vue-clipboard2'
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/theme/index.css'
import api from "@/api/index";
import utils from '@/utils';
import customModal from '@/components/customModal'
import zn_token from "@/utils/zn_token"
// 剪贴板
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment
Vue.prototype.$api = api
Vue.prototype.zn_token = zn_token()
Vue.prototype.role = (value = []) => value.includes(store.state.user.userInfo.role)
try {
	Object.keys(utils).forEach(e => {
		Vue.prototype[e] = utils[e];
	})
} catch (e) {
	console.log(e)
}
Vue.config.productionTip = false

Vue.component('customModal', customModal)
Vue.directive('role', {
	inserted: (el, binding) => {
		// 如果没有权限就移除此节点
		if (!binding.value.includes(store.state.user.userInfo.role)) {
			el.parentNode.removeChild(el);
		}
	}
})
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
