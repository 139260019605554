/* 
 * @Author: 曹俊杰 
 * @Date: 2020-09-07 14:29:35
 * @Module: 主播入会审批API
 */
import request from '@/utils/request'
export default {
	// 主播入会审批
	inviteListApi (params) {
		return request.post(`api/invite/list`, params)
	},
	// 通过审批
	inviteAccept (params) {
		return request.post(`api/invite/accept`, params)
	},
	// 获取星探资料
	getStarBroker (params) {
		return request.post(`mmapi/zn/get-star-broker`, params)
	},
	// 修改星探资料
	updateStarBroker (params) {
		return request.post(`api/star-anchor/update`, params)
	}
}
