import request from '@/utils/request'

export default {
    //公会管理表格数据
    guildpageApi (params) {
        return request.post(`api/mcn/list`, params)
      },
    //数据导入表格数据
    importDataApi (params) {
        return request.post(`api/import/list`, params)
    },
    //公会管理新增
    submitForm (params) {
        return request.post(`api/mcn/store`, params)
    },
     //公会管理编辑
    updataForm (params) {
        return request.post(`api/mcn/update/${params.id}`, params)
    },
    //公会管理删除
    guilDel (params) {
        return request.post(`api/mcn/delete/${params}`, params)
    },
    getProfitList (params) {
        return request.post(`api/profit/list`, params)
    },
    getOptionsList (params) {
        return request.post(`api/profit/plate-form`, params)
    },
    getNoticeList (params) {
        return request.post(`api/announce/list/${params.type}`, params)
    },
    importImgApi (params) {
        return request.upload(`api/upload/image`, params)
    },
    //获取公告详情
    getNoticeDetail (params) {
        return request.post(`api/announce/edit/${params.announce_id}`, params)
    },
    getCreate (params) {
        return request.post(`api/announce/create`, params)
    },
    //新增公告
    noticeAdd (params) {
        return request.upload(`api/announce/store`, params)
    },
    //编辑公告
    noticeEdit (params) {
        return request.upload(`api/announce/update/${params.announce_id}`, params)
    },
    //删除公告
    noticeDelApi (params) {
        return request.post(`api/announce/delete/${params.id}`, params)
    },
    //更新表格
    updatate (params) {
        return request.upload(`api/import/invite-state`, params)
    },
    //数据导入导入数据
    importUpdataApi (params) {
        return request.upload(`api/import/import`, params)
    },
    //是否置顶
    setTopApi (params) {
        return request.post(`api/announce/top/${params.id}`, params)
    }
}
