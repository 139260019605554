

const state = {
  showBreadcrumb: false,    //面包屑
  pathList: []
}

const mutations = {
  changeBreadcrumb (state, payload) {
    state.showBreadcrumb = payload
  },
  setPathList (state, payload) {
    state.pathList = payload
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}