import request from '@/utils/request'

export default {
  staffListApi (params) {
    return request.post(`api/staff/index`, params)
  },
  staffEditApi (params) {
    return request.post(`api/staff/edit/${params.id}`, params)
  },
  staffUpdateApi (params) {
    return request.post(`api/staff/update/${params.id}`, params)
  },
  staffAddApi (params) {
    return request.post(`api/staff/store`, params)
  }
}
