import request from '@/utils/request'

export default {
    //获取账户设置
    getAccountApi (params) {
        return request.post(`api/profile/profile`, params)
    },
    //修改密码
    changePwdApi (params) {
        return request.post(`api/profile/change-password`, params)
    },
    //获取实名认证信息
    getRealApi (params) {
        return request.post(`api/profile/verify-information`, params)
    },
    certifyApi (params) {
        return request.post(`api/profile/certify`, params)
    },
    //扫描二维码后提交接口
    certifyVerifyApi (params) {
        return request.post(`api/profile/confirm-verify`, params)
    },
    getSignApi () {
        return request.post(`api/contract/has-contract`)
    },
}
