

const state = {
  userInfo: null
}

const mutations = {
  setUserInfo(state, payload) {
    state.userInfo = payload
  },
  clearUserInfo(state){
    state.userInfo = null
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}