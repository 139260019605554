import request from '@/utils/request'

export default {
	announceApi (params) {
		return request.post(`api/home/announce/${params.type}`, params)
	},
	announceLinkApi (params) {
		return request.post(`api/announce/edit/${params.announce_id}`, params)
	},
	coreDataApi (params) {
		return request.post(`api/home/index`, params)
	},
	taskMytaskApi (params) {
		return request.post(`api/task/progress`, params)
	},
	fortuneApi (params) {
		return request.post(`api/broker/fortune`, params)
	},
	announcementApi (params) {
		return request.post(`api/announce/edit/${params.id}`, params)
	},
	systemIncomeApi (params) {
		return request.post(`api/home/system-income`, params)
	},
	contactApi (params) {
		return request.post(`api/home/contact-us`, params)
	},
	//紧急通知
	emergencyNoticeApi () {
		return request.post(`api/announce/emergency-notice`)
	}
}
