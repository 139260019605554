import request from '@/utils/request'
// 获取Local
const $getStorage = key => {
	return JSON.parse(localStorage.getItem(key))
};
// 储存Local
const $setStorage = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value));
};
// 导出表格
const $excelDownload = (params, url) => {
	const _window = window;
	const date = new Date();
	request.post(url, params).then(res => {
		let blob = new Blob(['\uFEFF' + res], {
			type: 'text/csv;charset=UTF-8'
		});
		let objectUrl = _window.URL.createObjectURL(blob); //创建新的URL表示指定Blob对象
		let a = document.createElement('a'); //创建a标签
		a.href = objectUrl; //指定下载链接
		a.download = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日${date.getHours()}时${date.getMinutes()}分导出数据.csv`; //指定下载文件名
		a.click(); //触发下载
		a.remove(); //除a标签
		_window.URL.revokeObjectURL(objectUrl); //释放
	});
}
// 格式化时间
const $timeFormat = (timestamp = null, fmt = 'yyyy-mm-dd') => {
	// 其他更多是格式化有如下:
	// yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合
	timestamp = parseInt(timestamp);
	// 如果为null,则格式化当前时间
	if (!timestamp) timestamp = Number(new Date());
	// 判断用户输入的时间戳是秒还是毫秒,一般前端js获取的时间戳是毫秒(13位),后端传过来的为秒(10位)
	if (timestamp.toString().length == 10) timestamp *= 1000;
	let date = new Date(timestamp);
	let ret;
	let opt = {
		"y+": date.getFullYear().toString(), // 年
		"m+": (date.getMonth() + 1).toString(), // 月
		"d+": date.getDate().toString(), // 日
		"h+": date.getHours().toString(), // 时
		"M+": date.getMinutes().toString(), // 分
		"s+": date.getSeconds().toString() // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (let k in opt) {
		ret = new RegExp("(" + k + ")").exec(fmt);
		if (ret) {
			fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
		}
	}
	return fmt;
}
let timer = null,
	flag = null;
/**
 * 节流原理：在一定时间内，只能触发一次
 * 
 * @param {Function} func 要执行的回调函数 
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
const $throttle = (func, wait = 500, immediate = true) => {
	console.log(timer)
	if (immediate) {
		if (!flag) {
			flag = true;
			// 如果是立即执行，则在wait毫秒内开始时执行
			typeof func === 'function' && func();
			timer = setTimeout(() => {
				flag = false;
			}, wait);
		}
	} else {
		if (!flag) {
			flag = true
			// 如果是非立即执行，则在wait毫秒内的结束处执行
			timer = setTimeout(() => {
				flag = false
				typeof func === 'function' && func();
			}, wait);
		}

	}
};
// 字符串转时间戳
const $dateFormat = (date) => {
	let time = ''
	if (date) {
		time = new Date(date.substring(0, 19).replace(/-/g, '/')).getTime();
	}
	console.log(time)
	return time
}
const $isMobile = () => {
	var userAgentInfo = navigator.userAgent;

	var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

	var mobile_flag = false;

	//根据userAgent判断是否是手机
	for (var v = 0; v < mobileAgents.length; v++) {
		if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
			mobile_flag = true;
			break;
		}
	}
	var screen_width = window.screen.width;
	var screen_height = window.screen.height;

	//根据屏幕分辨率判断是否是手机
	if (screen_width < 500 && screen_height < 800) {
		mobile_flag = true;
	}

	return mobile_flag;
}
export default {
	$setStorage,
	$getStorage,
	$excelDownload,
	$timeFormat,
	$throttle,
	$dateFormat,
	$isMobile
}